import React from "react"
import { FaPaypal } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

import "./contact.styles.scss"

const ContactPage = ({ location }) => {
  const orderDetails = location ? location.state ? location.state.orderData ? location.state.orderData : null : null : null 
  return (
    <Layout className="contact" pathname={location.pathname}>
      <Seo
        title="Order Confirmation"
        description="Thank you for your purchase."
      />
      <section className="hero contact__hero is-medium">
        <div className="container container--text">
          <div className="hero-body is-flex is-flex-direction-column is-align-items-center has-text-centered">
            <h1 className="title is-size-2-mobile is-size-1-tablet">
              Thank you!
            </h1>
          </div>
        </div>
      </section>
      <section className="contact__form">
        <div className="container">
          <h2 className="is-size-4">Order Confirmation</h2>
          <p className="is-size-6">
            Below you will find the details of your order from Dan Parasky.
            Please print this order confirmation for your records.
          </p>
          {orderDetails ? (
            <div>
              <p className="is-size-5 mt-6">
                Order number:{" "}
                <span className="has-text-weight-semibold">
                  #{orderDetails.purchase_units[0].payments.captures[0].id}
                </span>
              </p>
              <p className="is-size-5">
                Description:{" "}
                <span className="has-text-weight-semibold">
                  {orderDetails.purchase_units[0].description}
                </span>
              </p>
              <div className="columns mt-6">
                <div className="column is-full-mobile is-half-tablet">
                  <p className="is-size-5 has-text-weight-semibold">
                    Primary Contact
                  </p>
                  <p className="is-size-6">
                    {orderDetails.purchase_units[0].shipping.name.full_name}
                    <br />
                    {orderDetails.purchase_units[0].payee.email_address}
                    <br />
                    {orderDetails.purchase_units[0].shipping.address.address_line_1}
                    <br />
                    {orderDetails.purchase_units[0].shipping.address.admin_area_2}
                    {", "}
                    {orderDetails.purchase_units[0].shipping.address.admin_area_2}
                    {" "}
                    {orderDetails.purchase_units[0].shipping.address.postal_code}
                    <br />
                    {orderDetails.purchase_units[0].shipping.address.country_code}
                  </p>
                </div>
                <div className="column is-full-mobile is-half-tablet">
                  <p className="is-size-5 has-text-weight-semibold">
                    Payment Method
                  </p>
                  <p className="is-size-6">
                    <FaPaypal style={{ color: "#003087" }}/> PayPal
                  </p>
                </div>
              </div>
              <p className="is-size-5 has-text-weight-semibold mt-6">
                Summary
              </p>
              <div className="columns mt-2">
                <div className="column is-8 is-size-6">
                  {orderDetails.purchase_units[0].description} x 1
                </div>
                <div className="column is-4 is-size-6 has-text-right">
                  $
                  {orderDetails.purchase_units[0].payments.captures[0].amount.value}
                    {" "}
                  {orderDetails.purchase_units[0].payments.captures[0].amount.currency_code}
                </div>
              </div>
              <div style={{ borderBottom: "1px solid #b3b3b3" }} />
              <div className="columns mt-2">
                <div className="column is-8 is-size-6">
                  Subtotal
                </div>
                <div className="column is-4 is-size-6 has-text-right">
                  $
                  {orderDetails.purchase_units[0].payments.captures[0].amount.value}
                    {" "}
                  {orderDetails.purchase_units[0].payments.captures[0].amount.currency_code}
                </div>
              </div>
              <div style={{ borderBottom: "1px solid #b3b3b3" }} />
              <div className="columns mt-2">
                <div className="column is-8 is-size-6 has-text-weight-semibold">
                  Total
                </div>
                <div className="column is-4 is-size-6 has-text-right has-text-weight-semibold">
                  $
                  {orderDetails.purchase_units[0].payments.captures[0].amount.value}
                    {" "}
                  {orderDetails.purchase_units[0].payments.captures[0].amount.currency_code}
                </div>
              </div>
              <div style={{ borderBottom: "1px solid #b3b3b3" }} />
              <p className="is-size-6 has-text-grey-dark mt-6">
                You will be contacted to schedule your coaching sessions using the
                email address above. I look forward to working with you and
                providing the best information out there about studio ensemble
                building.
              </p>
              <p className="is-size-6 has-text-grey-dark has-text-weight-semibold mt-4">
                Thank you!
              </p>
            </div>
          ) : (
            <p className="is-size-6 py-6 has-text-weight-semibold">No order data found</p>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
